import React, { useState } from 'react';
import deleteIcon from '../assets/delete-icon.png'; // Import delete icon
import './AccountDeletion.css'; // Import AccountDeletion.css for styling

function AccountDeletion() {
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Account deletion request submitted for email:', email);
  };

  return (
    <div className="page-container">
      <h1 className="page-title">Account Deletion</h1>
      <img src={deleteIcon} alt="Delete Icon" className="page-icon" />
      <p>To request us to delete your data please send an email to:</p>
      <p>goomi@sundaycreatives.co.za</p>

      <p>with your name, email address and phone number used to register on the app</p>

      {/* <p>Please enter you email address below</p>
      <form onSubmit={handleSubmit}>
        <label>
          Email:
          <input type="email" value={email} onChange={handleEmailChange} required />
        </label>
        <button type="submit">Submit</button>
      </form> */}
    </div>
  );
}

export default AccountDeletion;

// https://www.sundaycreatives.co.za/goomidelete